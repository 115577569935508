<template>
	<div class="container">
		<img id="astronaut7" class="astronaut7" src="~@/assets/images/index/astronaut.png" />
	</div>
</template>

<script>
	const TWEEN = require('@tweenjs/tween.js')
	
	export default {
		name: 'test4',
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				pixelRatio: window.pixelRatio,
				
				astronautLastCoords: {x: 0, y: 0},
				astronautCoords: {x: window.innerWidth / 2, y: window.innerHeight / 2},
				astronautTween: null,
				astronautTime: 0,
			}
		},
		mounted() {
			this.createAstronautRun()
			
			window.addEventListener('mousemove', (event) => {
				console.log('mousemove: ', event)
			
				let t = new Date().getTime()
				if(Math.abs(this.astronautLastCoords.x - event.x) > 50 || 
					Math.abs(this.astronautLastCoords.y - event.y) > 50) {
					this.astronautTween.stop()
					
					this.astronautCoords = {x: event.x, y: event.y}
				}
			})
		},
		destroyed() {
			
		},
		methods: {
			createAstronautRun() {
				let astronaut7 = document.getElementById('astronaut7')
				
				this.astronautTween = null
				this.astronautTween = new TWEEN.Tween(this.astronautLastCoords)
					.to(this.astronautCoords, 10000)
					.easing(TWEEN.Easing.Quadratic.Out)
					.onUpdate(() => {
						astronaut7.style.left = this.astronautLastCoords.x + 'px'
						astronaut7.style.top = this.astronautLastCoords.y + 'px'
						
						// this.astronautLastCoords = this.astronautCoords
					})
					.onStop(() => {
						console.log('onStop')
						this.createAstronautRun()
					})
					.onComplete(() => {
						console.log('complete')
					})
					
				this.astronautTween.start()
				this.astronautTime = new Date().getTime()
					
				window.requestAnimationFrame(this.animate)
			},
			animate(time) {
				window.requestAnimationFrame(this.animate)
				
				// let current = new Date().getTime()
				// console.log('current: ', current)
				// console.log('update: ', time)
				
				TWEEN.update()
			},
		}
	}
</script>

<style scoped>
	.container {
		width: 100%;
		height: 100%;
		
		overflow: hidden;
	}
	
	.astronaut7 {
		position: absolute;
		left: 0%;
		top: 0%;
	
		width: 13%;
	
		/* transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg) scale(1.2); */
	}
</style>
